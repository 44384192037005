























import { Component, Prop, Vue } from 'vue-property-decorator';
import CustomButton from '@/components/CustomButton.vue';
import { EButtonMode } from '@/common/enums/button.enum';
import { ITexts } from '@/common/interfaces/index.interface';

@Component({
  components: { CustomButton },
  name: `PreferencesFooter`,
})
export default class PreferencesFooter extends Vue {
  @Prop({ required: true, type: Object as () => ITexts })
  protected texts!: ITexts;

  protected EButtonMode = EButtonMode;
}
