import { ICookieCategory } from '@/common/interfaces/index.interface';

export const essentialCookiesCategoryName = `necessary`;
export const cookieNoticeCookieName = `ccn-interacted`;

const cookieCategories: ICookieCategory[] = [
  {
    active: true, // preselect category
    cookies: [
      {
        active: true,
        cookieNames: `ccn-interacted`,
        duration: `1 Jahr`,
        explanation: ``,
        name: `ccn-interacted`,
        privacy: ``,
        responsible: `Eigentümer dieser Website`,
        title: `Cookie-Notice`,
        value: true,
      },
      {
        active: true,
        cookieNames: `CONCRETE5`,
        duration: `Aktuelle Session`,
        explanation: `Falls Sie sich einloggen, wird ihre Session mit ihrem Benutzerkonto verknüpft.
        Es verrät dem System, dass sie Cookies unterstützen. Wenn Sie sich nicht einloggen, ist dieses Cookie ohne jegliche relevanz.
        Das Cookie ermöglicht keine Rückschlüsse auf ein Gerät, eine Person, oder einen Browser.`,
        name: `concrete5`,
        privacy: ``,
        responsible: `Eigentümer dieser Website`,
        title: `Session-Cookie`,
        value: true,
      },
    ],
    explanation: `Essenzielle Cookies ermöglichen grundlegende Funktionen und sind für die einwandfreie Funktion der Website erforderlich.`,
    indeterminate: false,
    name: `necessary`,
    title: `Essenziell`,
  },
  {
    active: false,
    cookies: [
      {
        active: false,
        cookieNames: `_ga, _gat, _gid, _gcl_au`,
        duration: `2 Jahre`,
        explanation: ``,
        name: `ga`,
        privacy: `Google LLC`,
        responsible: `Google LLC`,
        title: `Statistik & Analyse (Google Analytics & Google Tag Manager)`,
        value: true,
      },
    ],
    explanation: `Statistik Cookies erfassen Informationen anonym. Diese Informationen helfen uns zu verstehen, wie unsere Besucher unsere Website nutzen.`,
    indeterminate: false,
    name: `statistic`,
    title: `Statistiken`,
  },
];

export default cookieCategories;
