







































import { Component, Prop, Vue } from 'vue-property-decorator';
import { ETextSize } from '@/common/enums/index.enum';
import HeadlineWithToggle from '@/components/HeadlineWithToggle.vue';
import CookieList from '@/components/CookieList.vue';
import { ICookieListEntry, ITexts } from '@/common/interfaces/index.interface';
import ExplanationParagraph from '@/components/ExplanationParagraph.vue';

@Component({
  components: { CookieList, ExplanationParagraph, HeadlineWithToggle },
  name: `CookieCategory`,
})
export default class CookieCategory extends Vue {
  @Prop({ default: false, type: Boolean })
  protected active!: boolean;

  @Prop({ required: true, type: Array as () => ICookieListEntry[] })
  protected cookies!: ICookieListEntry[];

  @Prop({ default: false, type: Boolean })
  protected disabled!: boolean;

  @Prop({ default: ``, type: String })
  protected explanation!: string;

  @Prop({ required: true, type: String })
  protected headline!: string;

  @Prop({ default: false, type: Boolean })
  protected indeterminate!: boolean;

  @Prop({ required: true, type: String })
  protected categoryName!: string;

  @Prop({ required: true, type: Object as () => ITexts })
  protected texts!: ITexts;

  protected EHeadlineSize = ETextSize;
  protected showCookieList = false;
}
