






































import { Component, Prop, Vue } from 'vue-property-decorator';
import Headline from '@/components/Headline.vue';
import CookieCategory from '@/components/CookieCategory.vue';
import PreferencesFooter from '@/components/PreferencesFooter.vue';
import { ICookieCategory, ITexts } from '@/common/interfaces/index.interface';

@Component({
  components: { CookieCategory, Headline, PreferencesFooter },
  name: `Preferences`,
})
export default class Preferences extends Vue {
  @Prop({ required: true, type: Array as () => ICookieCategory[] })
  protected cookieCategories!: ICookieCategory[];

  @Prop({ required: true, type: Object as () => ITexts })
  protected texts!: ITexts;

  @Prop({ required: true, type: Boolean })
  protected visible!: boolean;
}
