







































































import { Component, Vue } from 'vue-property-decorator';
import CustomButton from '@/components/CustomButton.vue';
import { EButtonMode } from '@/common/enums/button.enum';
import Preferences from '@/components/Preferences.vue';
import CookieHelper from '@/helpers/cookie-helper';
import { ICookieCategory, ITexts } from '@/common/interfaces/index.interface';
import DomEventHelper from '@/helpers/dom-event-helper';
import Headline from '@/components/Headline.vue';
import texts from '../../configs/texts';
import cookieCategories, { cookieNoticeCookieName, essentialCookiesCategoryName } from '../../configs/cookie-list';

@Component({
  components: { CustomButton, Headline, Preferences },
  name: `CookieNotice`,
})
export default class CookieNotice extends Vue {
  protected acceptedCookieNames: Set<string> = new Set();
  protected cookieCategories: ICookieCategory[] = JSON.parse(JSON.stringify(cookieCategories));
  protected EButtonMode = EButtonMode;
  protected showCookieNotice = true;
  protected showPreferences = false;
  protected texts: ITexts = texts;

  protected async mounted(): Promise<void> {
    if(await CookieHelper.getCcnInteracted() && await CookieHelper.checkVersion()) {
      this.hideCookieNotice();
    } else {
      document.body.classList.add(`ccn-overflow-hidden`);
    }
  }

  protected acceptAll(): void {
    this.setNecessaryCookies();
    this.setAllOptionalCookies();
    CookieHelper.setCcnInteracted(this.acceptedCookieNames).then(() => this.hideCookieNotice());
  }

  protected declineAll(): void {
    this.setNecessaryCookies();
    this.unsetAllOptionalCookies();
    CookieHelper.setCcnInteracted(this.acceptedCookieNames).then(() => this.hideCookieNotice());
  }

  protected acceptSelection(): void {
    this.setNecessaryCookies();
    this.setSelectedOptionalCookies();
    CookieHelper.setCcnInteracted(this.acceptedCookieNames).then(() => this.hideCookieNotice());
  }

  protected hideCookieNotice(): void {
    this.showCookieNotice = false;
    this.showPreferences = false;
    document.body.classList.remove(`ccn-overflow-hidden`);
  }

  protected unsetAllOptionalCookies(): void {
    const optionalCookieCategories = this.cookieCategories.filter(category => category.name !== essentialCookiesCategoryName);
    for(const optionalCookieCategory of optionalCookieCategories) {
      for(const cookie of optionalCookieCategory.cookies) {
        this.acceptedCookieNames.delete(cookie.name);
      }
    }
  }

  protected setAllOptionalCookies(): void {
    const optionalCookieCategories = this.cookieCategories.filter(category => category.name !== essentialCookiesCategoryName);

    if(optionalCookieCategories.length > 0) {
      for(const optionalCookieCategory of optionalCookieCategories) {
        for(const cookie of optionalCookieCategory.cookies) {
          DomEventHelper.fireCookieSetEvent({ name: cookie.name });
          this.acceptedCookieNames.add(cookie.name);
        }
      }
    }
  }

  protected setSelectedOptionalCookies(): void {
    const optionalCookieCategories = this.cookieCategories.filter(category => category.name !== essentialCookiesCategoryName);

    if(optionalCookieCategories.length > 0) {
      for(const optionalCookieCategory of optionalCookieCategories) {
        for(const cookie of optionalCookieCategory.cookies) {
          if(cookie.active) {
            DomEventHelper.fireCookieSetEvent({ name: cookie.name });
            this.acceptedCookieNames.add(cookie.name);
          } else {
            this.acceptedCookieNames.delete(cookie.name);
          }
        }
      }
    }
  }

  protected setNecessaryCookies(): void {
    const necessaryCookieCategory = this.cookieCategories.find(categegory => categegory.name === essentialCookiesCategoryName);
    if(necessaryCookieCategory) {
      for(const cookie of necessaryCookieCategory.cookies) {
        if(cookie.name === cookieNoticeCookieName) {
          // no need to fire an event for a cookie the notice itself sets
        } else {
          DomEventHelper.fireCookieSetEvent({ name: cookie.name });
          this.acceptedCookieNames.add(cookie.name);
        }
      }
    }
  }

  protected showPreferencesOverlay(): void {
    this.showPreferences = true;
  }

  protected updateAllCookiesOfCategory(
    active: boolean,
    indexOfCategory: number,
  ): void {
    for(const cookie of this.cookieCategories[indexOfCategory].cookies) {
      cookie.active = active;
    }
  }

  protected updateCategory(category: string): void {
    const cookieCategoryIndex = this.cookieCategories.findIndex(cookie => cookie.name === category);
    if(cookieCategoryIndex > -1) {
      const activeCookies = this.cookieCategories[cookieCategoryIndex].cookies.filter(cookie => cookie.active);
      if(activeCookies.length > 0) {
        if(activeCookies.length === this.cookieCategories[cookieCategoryIndex].cookies.length) {
          this.cookieCategories[cookieCategoryIndex].active = true;
          this.cookieCategories[cookieCategoryIndex].indeterminate = false;
        } else {
          this.cookieCategories[cookieCategoryIndex].active = false;
          this.cookieCategories[cookieCategoryIndex].indeterminate = true;
        }
      } else {
        this.cookieCategories[cookieCategoryIndex].active = false;
        this.cookieCategories[cookieCategoryIndex].indeterminate = false;
      }
    }
  }

  /* eslint-disable no-param-reassign */
  protected updateCategoryActive(event: { active: boolean; category: ICookieCategory }): void {
    event.category.active = event.active;
    event.category.indeterminate = false;
    const cookieCategoryIndex = this.cookieCategories.findIndex(cookie => cookie.name === event.category.name);
    this.cookieCategories.splice(cookieCategoryIndex, 1, event.category);
    this.updateAllCookiesOfCategory(event.active, cookieCategoryIndex);
  }

  /* eslint-enable no-param-reassign */
  protected updateSingleCookieActive(event: { active: boolean; cookieCategoryName: string; cookieName: string }): void {
    const cookieCategoryIndex = this.cookieCategories.findIndex(category => category.name === event.cookieCategoryName);
    const cookie = this.cookieCategories[cookieCategoryIndex].cookies.find(c => c.name === event.cookieName);
    const cookieIndex = this.cookieCategories[cookieCategoryIndex].cookies.findIndex(c => c.name === event.cookieName);
    if(cookie && cookieIndex > -1) {
      cookie.active = event.active;
      this.cookieCategories[cookieCategoryIndex].cookies.splice(cookieIndex, 1, cookie);
    }
    this.updateCategory(event.cookieCategoryName);
  }
}
