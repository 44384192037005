import { ITexts } from '@/common/interfaces/index.interface';

const texts: ITexts = {
  buttons: {
    acceptAll: `Alle akzeptieren`,
    declineAll: `Alle ablehnen`,
    saveSelection: `Auswahl speichern`,
    showPreferences: `Präferenzen aktualisieren`,
  },
  cookieDetails: {
    cookieNames: `Namen der Cookies`,
    duration: `Laufzeit der Cookies`,
    privacy: `Datenschutzerklärung`,
    responsible: `Anbieter`,
  },
  links: [
    {
      openPreferences: true,
      text: `Mehr Informationen`,
    },
    {
      href: `https://yarrive.com/datenschutzerklaerung/`,
      openPreferences: false,
      text: `Datenschutzerklärung`,
    },
    {
      href: `/#/data-protection`,
      openPreferences: false,
      text: `Nutzungsbedingungen`,
    },
    {
      href: `https://static.kfv.at/`,
      openPreferences: false,
      text: `Impressum`,
    },
  ],
  notice: {
    headline: `Diese Website verwendet Cookies`,
    // eslint-disable-next-line max-len
    text: `Wir verwenden technisch notwendige Cookies zur Benutzeridentifikation und Cookies um die Zugriffe auf unsere Website zu analysieren, damit wir die Plattform weiter verbessern können.`,
  },
  preferences: {
    explanation: `Hier findest du eine Übersicht über alle verwendeten Cookies.
    Du kannst deine Einwilligung zu ganzen Kategorien geben oder dir weitere Informationen anzeigen lassen und so nur bestimmte Cookies auswählen.`,
    headline: `Datenschutzeinstellungen`,
    listCookies: `Cookies auflisten`,
  },
};

export default texts;
